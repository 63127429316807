import React, { useEffect } from 'react';

import FullButton from '@components/buttons/FullButton';
import FlexBox from '@components/layout/FlexBox';
import settings from '@settings';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { slideInAnimation } from './animations';
import { buttonContainer, contentContainer, overlay, popup } from './styles';

const SlideIn = ({ children, open, cancelButtonHandler, width, hasNoOverflow, duration}) => {

	const widthHandler = () => {
		if (width === 'small') {
			return ['95vw', '95vw','95vw', '33vw'];
		}
		if (width === 'medium') {
			return ['95vw', '95vw','95vw', '50vw'];
		}
		if (width === 'large') {
			return ['95vw', '95vw','95vw', '95vw'];
		}
		return null;
	};

	useEffect(() => {
		if (open === false ) { 
			const {body} = document;
			const html = document.documentElement;
			setTimeout(() => {
				body.style.overflowY = 'visible';
				body.style.paddingRight = '0px';
				html.style.overflowY = 'visible';
			}, [duration * 1000]);
		}

		if (open === true) {
			document.documentElement.style.getPropertyValue('--scroll-y');
			const {body} = document;
			const html = document.documentElement;
			body.style.overflowY = 'hidden';
			body.style.paddingRight = '10px';
			html.style.overflowY = 'hidden';
		}
	},[open, duration]);

	if (typeof window !== 'undefined') {
		return ReactDOM.createPortal(
			<AnimatePresence>
				{open && 
					<>
						<motion.div
							key="slideIn-overlay"
							transition={{duration}}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							css={overlay}
						/>
						<motion.div
							key="slidein-animation"
							variants={slideInAnimation}
							custom={duration}
							initial="initial"
							animate="animate"
							exit="exit"
							css={settings.mq([popup, {width: widthHandler()}])}
						>
							<FlexBox
								direction="column"
								align="flex-start"
								justify='space-between'
								styles={{height: '100%', overflow: 'hidden'}}
							>
								<div css={[contentContainer, {overflow: hasNoOverflow ? 'hidden' : 'auto'}]}>{children}</div>
								<div css={buttonContainer}>
									<FullButton
										text='Schließen'
										size='medium'
										coilor='primary'
										type='button'
										onClick={() => cancelButtonHandler()}
									/>
								</div>
							</FlexBox>
						</motion.div>
					</>
				}
			</AnimatePresence>,
			document.body
		); 
	}
	return null;
};

SlideIn.propTypes = {
	children: PropTypes.node.isRequired,
	open: PropTypes.bool,
	cancelButtonHandler: PropTypes.func.isRequired,
	width: PropTypes.oneOf(['small', 'medium', 'large']),
	hasNoOverflow: PropTypes.bool,
	duration: PropTypes.number
};

SlideIn.defaultProps = {
	open: false,
	width: 'small',
	hasNoOverflow: false,
	duration: 0.4
};

export default SlideIn;
