export const slideInAnimation = {
	initial: {
		x: '100%'
	},
	animate: duration => ({
		x: '0%',
		transition: {
			ease: 'easeOut',
			duration
		}
	}),
	exit: duration => ({
		x: '100%',
		transition: {
			x: {
				ease: 'easeIn',
				duration
			}
		}
	})
};