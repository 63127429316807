/* eslint-disable react/prop-types */
import React from 'react';

import Athlete from '@content/Athlete';
import SiteHead from '@mainComponents/SiteHead';

const AthleteProfileTemplate = ({pageContext}) => <Athlete id={pageContext.id} />;

export const Head = ({location}) => <SiteHead location={location} />;

export default AthleteProfileTemplate;