import settings from '@settings';

export const imageContainer = settings.mq({
	display: ['none', 'none', 'block'], 
	overflow: 'visible',
	maxWidth: '400px'
	// height:'400px'
});

export const imageContainerInline = settings.mq({
	display: ['block', 'block', 'none'], 
	marginTop: settings.sizes.small,
	marginBottom: settings.sizes.large,
	width: '100%', 
	height: 'auto',
	overflow: 'hidden'
});