import React, { useState } from 'react';

import FullButton from '@components/buttons/FullButton';
import Container from '@components/Container';
import FlexBox from '@components/layout/FlexBox';
import Wrapper from '@components/layout/Wrapper';
import Divider from '@components/surfaces/Divider';
import Gallery from '@components/surfaces/ImageGallery';
import SlideIn from '@components/surfaces/SlideIn';
import Topline from '@components/surfaces/Topline';
import useGetAthlete from '@hooks/useGetAthlete';
import useGetImages from '@hooks/useGetImages';
import useGetColors from '@hooks/useGetSiteColors';
import Layout from '@mainComponents/Layout';
import settings from '@settings';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { BsFacebook, BsInstagram } from 'react-icons/bs';

import { imageContainer, imageContainerInline } from './styles';

const Athlete = ({id}) => {
	const [interview, setInterview] = useState();
	const {secondaryShaded} = useGetColors();
	const {images} =  useGetImages(id);

	const {getAthlete} = useGetAthlete();
	const athlete = getAthlete(id);
	const portrait = getImage(athlete.portrait);

	const birthdayHandler = (birthday) => {
		const today = new Date();
		const birthDate = new Date(birthday);
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age-=1;
		}
		return age;
	};

	return (
		<Layout>
			<Wrapper >
				<Container styles={{marginTop: '100px'}}>
					<FlexBox
						direction='row'
						align='center'
						justify='space-evenly'
						hasFullWidth
						changeToColumn
					>
						<FlexBox direction='column' gap={settings.sizes.small} styles={{flex: '0 0 auto'}}>
							<Topline text='Athlete Profile'  />					
							<div css={settings.mq({display: ['none', 'none', 'block']})}>
								<h1>
									{settings.functions.breakSpaces(athlete.name, 'h1')}
								</h1>
							</div>
							<div css={settings.mq({display: ['block', 'block', 'none']})}>
								<h1>
									{athlete.name}
								</h1>
							</div>
							<div css={imageContainerInline}>
								{portrait && <GatsbyImage image={portrait} alt={athlete.name} height={400}/>}
							</div>
							{athlete.birthday && 
								<p>
									<span css={{fontWeight: 600}}>Age: </span> {birthdayHandler(athlete.birthday)}
								</p>
							}
							{athlete.nation && 
								<p>
									<span css={{fontWeight: 600}}>Nationality: </span> <img src={athlete.nation.flag.url} alt={athlete.nation.name} height={12} /> {athlete.nation.short}
								</p>
							}
							{athlete.facebook || athlete.instagram && 
								<p>
									<span css={{fontWeight: 600}}>Follow:</span> {athlete.facebook && <a href={athlete.facebook} target='__blank' rel='nofollow'> <BsFacebook /></a>} {athlete.instagram && <a href={athlete.instagram} target='__blank' rel='nofollow'> <BsInstagram /></a>}
								</p>
							}
							{athlete.description && 
								<p>
									<span css={{fontWeight: 600}}>Description:</span> {athlete.description}
								</p>
							}
							{athlete.interview?.html && athlete.interview?.html.length > 10 &&
								<FullButton
									color='secondary'
									hoverColor={secondaryShaded}
									size='medium'
									text={`More about ${athlete.name}`}
									onClick={() => setInterview(true)}
								/>
							}
						</FlexBox>
						<div css={imageContainer}>
							{portrait && <GatsbyImage image={portrait} alt={athlete.name} height={400}/>}
						</div>
					</FlexBox>
				</Container>
			</Wrapper>
			{images?.length > 0 &&
				<>
					<Wrapper>
						<Container noMargins>
							<Divider />
						</Container>
					</Wrapper>
					<Wrapper >
						<Container>
							<FlexBox
								direction='column'
								align='center'
								hasFullWidth
								gap={settings.sizes.large}
							>
								<h2>
									Gallery
								</h2>
								<Gallery images={images} />
							</FlexBox>
						</Container>
					</Wrapper>
				</>
			}
			<SlideIn
				open={interview}
				cancelButtonHandler={() => setInterview(false)}
			>
				<div dangerouslySetInnerHTML={{__html: athlete.interview?.html}} />
			</SlideIn>
		</Layout>
	);
};

Athlete.propTypes = {
	id: PropTypes.string.isRequired
};

export default Athlete;